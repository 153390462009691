import React from "react";
import { Td } from "@chakra-ui/react";
import { Box } from "@chakra-ui/layout";

interface CardOrderOrderStatusLozengeProps {
  orderStatus?: string | null;
}

export const CardOrderOrderStatusLozenge = ({
  orderStatus,
}: CardOrderOrderStatusLozengeProps) => {
  let backgroundColor = "#c4c4c4";
  switch (orderStatus) {
    case "Ordered":
      backgroundColor = "#c41836";
      break;
    case "Completed":
      backgroundColor = "#10a958";
      break;
    case "Cancelled":
      backgroundColor = "#f24e1d";
      break;
    case "Archived":
      backgroundColor = "#000000";
      break;
  }

  let textColour = orderStatus === "Incomplete" ? "black" : "white";
  return (
    <Td whiteSpace="nowrap">
      <Box
        sx={{
          backgroundColor: backgroundColor,
          borderRadius: "5px",
          color: textColour,
          padding: "2px 0",
          textAlign: "center",
          textTransform: "uppercase",
        }}
        fontSize={"xs"}
      >
        {orderStatus}
      </Box>
    </Td>
  );
};
