import { ApiEnumConfigurations } from "../../enums/ApiEnumConfigurations";
import { useApiRequest } from "../../hooks/useApiRequest";
import React from "react";
import { Select } from "chakra-react-select";
import { Box, FormControl, FormLabel } from "@chakra-ui/react";

interface CardOrderFilterOrderStatus {
  businessId: string;
  value: {
    label: string;
    value: string;
  };
  setValue: (value: { label: string; value: string }) => void;
}

export const ALL_OPTION = "All";

export const CardOrderFilterOrderStatus = ({
  businessId,
  value,
  setValue,
}: CardOrderFilterOrderStatus) => {
  const { data: cardOrderStatuses, isLoading } = useApiRequest(
    (apiClient) =>
      apiClient.getEnumConfigurations(
        businessId,
        ApiEnumConfigurations.CherryPayCardOrderStatus
      ),
    []
  );

  const orderStatuses = [
    {
      label: ALL_OPTION,
      value: ALL_OPTION,
    },
    ...(cardOrderStatuses?.map((item) => {
      return {
        label: item.Key!,
        value: item.Value! as string,
      };
    }) || []),
  ];

  return (
    <Box p="4" sx={{ width: "210px" }}>
      <FormControl>
        <FormLabel>Order status</FormLabel>
        <Select
          selectedOptionColor="blue"
          isLoading={isLoading}
          options={orderStatuses}
          value={value}
          onChange={(value) => {
            if (value) {
              setValue(value);
            }
          }}
        />
      </FormControl>
    </Box>
  );
};
