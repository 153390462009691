import React from "react";
import { Td } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/layout";
import { CherryPayApi } from "../../api/models";
import { CardOrderCancelAction } from "./CardOrderCancelAction";
import { CardOrderArchiveAction } from "./CardOrderArchiveAction";

interface CardOrderActionsProps {
  businessId: string;
  cardOrderId: string;
  orderStatus: CherryPayApi.CherryPayCardOrderStatus;
  refreshTable: () => void;
}

export const CardOrderActions = ({
  businessId,
  cardOrderId,
  orderStatus,
  refreshTable,
}: CardOrderActionsProps) => {
  return (
    <Td whiteSpace="nowrap">
      <Flex>
        <CardOrderCancelAction
          businessId={businessId}
          cardOrderId={cardOrderId}
          orderStatus={orderStatus}
          refreshTable={refreshTable}
        />
        <CardOrderArchiveAction
          businessId={businessId}
          cardOrderId={cardOrderId}
          orderStatus={orderStatus}
          refreshTable={refreshTable}
        />
      </Flex>
    </Td>
  );
};
