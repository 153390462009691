import React from "react";
import { Tooltip } from "@chakra-ui/react";
import { Box } from "@chakra-ui/layout";
import { TrashIcon } from "../../styles/icons";
import { useApiClient } from "../../hooks/useApiClient";
import { CherryPayApi } from "../../api/models";
import { useToast } from "../../hooks/useToast";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";

interface CardOrderActionsProps {
  businessId: string;
  cardOrderId: string;
  orderStatus: CherryPayApi.CherryPayCardOrderStatus;
  refreshTable: () => void;
}

export const CardOrderArchiveAction = ({
  businessId,
  cardOrderId,
  orderStatus,
  refreshTable,
}: CardOrderActionsProps) => {
  const apiClient = useApiClient();
  const { showErrorToast, showSuccessToast } = useToast();
  const { confirmationElement, showConfirmationDialog } =
    useConfirmationDialog();

  const onArchiveOrder = () => {
    showConfirmationDialog(
      "Archive Order?",
      `Are you sure you wish to archive the order ${cardOrderId.substring(
        0,
        8
      )}?`,
      "Yes, Archive Order",
      "No, Do not archive"
    ).then(({ ok }) => {
      if (ok) {
        apiClient
          .archiveCardOrder(businessId, cardOrderId)
          .then((result) => {
            if (result.ok) {
              showSuccessToast("Order archive successfully");
              refreshTable();
            } else {
              showErrorToast(
                "Failed to archive order. Error: " + result.message
              );
            }
          })
          .catch((error) => {
            showErrorToast("Failed to archive order. Error: " + error.message);
          });
      }
    });
  };

  const isIconEnabled = ["Ordered", "Completed"].includes(orderStatus);
  const tooltipText = isIconEnabled
    ? "Archive Order"
    : "Only Ordered and Completed orders can be archived";

  return (
    <Box>
      <Tooltip label={tooltipText} fontSize="md">
        <span>
          <TrashIcon
            onClick={isIconEnabled ? onArchiveOrder : undefined}
            style={{
              cursor: isIconEnabled ? "pointer" : "not-allowed",
              opacity: isIconEnabled ? 1 : 0.5,
            }}
          />
        </span>
      </Tooltip>
      {confirmationElement}
    </Box>
  );
};
