import { HStack } from "@chakra-ui/layout";
import {
  Box,
  Button,
  Divider,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { SingleValue } from "chakra-react-select";
import { FormikErrors, FormikProps, withFormik } from "formik";

import React, { useEffect } from "react";
import { ApiClient, ApiResult } from "../../api/apiClient";
import { CherryPayApi } from "../../api/models";
import {
  ChakraReactSelectField,
  SelectOption,
} from "../../components/fields/SelectField/ChakraReactSelectField";
import { TextField } from "../../components/fields/TextField/TextField";
import { useApiRequest } from "../../hooks/useApiRequest";
import { MemberAddressForm } from "./MemberAddressForm";
import { MemberContactForm } from "./MemberContactForm";
import { isEmptyStr } from "../../util/StringUtil";
import { isValidEmail } from "../../util/isValidEmail";
import { AddressType, AddressTypeList } from "../../enums/AddressType";
import { Card } from "../../components/Card/Card";
import { ContactType, ContactTypeList } from "../../enums/ContactType";
import { v4 as uuid } from "uuid";
import { DateField } from "../../components/DateField/DateField";

interface MemberFormProps {
  apiClient: ApiClient;
  businessId: string;
  member?: CherryPayApi.Member | null;
  memberFullName: string | null;
  onCancel: () => void;
  onSuccess: (member: CherryPayApi.Member) => void;
  onFailure: (message?: string) => void;
}

export interface MemberFormValues {
  memberKey?: string | null;
  venueId?: string | null;
  membershipNumber?: string | null;
  badgeNumber?: string | null;
  cardNumber?: string | null;
  title?: string | null;
  firstName?: string | null;
  preferredName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  birthDate?: string | null;
  nationality?: string | null;
  dateJoined?: string | null;
  dateRenewed?: string | null;
  dateFinancial?: string | null;
  financial?: boolean | null;
  status?: string | null;
  gender?: string | null;
  occupation?: string | null;
  memberLevel?: string | null;
  memberLevelDescription?: string | null;
  membershipType?: string | null;
  membershipTypeDescription?: string | null;
  defaultAddress?: AddressType;
  homeAddress?: CherryPayApi.Address | null;
  workAddress?: CherryPayApi.Address | null;
  postalAddress?: CherryPayApi.Address | null;
  tempGeocodedAddress?: CherryPayApi.GeocodedAddress | null;
  geocodedHomeAddress?: CherryPayApi.GeocodedAddress | null;
  geocodedWorkAddress?: CherryPayApi.GeocodedAddress | null;
  geocodedPostalAddress?: CherryPayApi.GeocodedAddress | null;
  defaultContactDetails?: ContactType;
  homeContactDetails?: CherryPayApi.Contact;
  workContactDetails?: CherryPayApi.Contact;
  postalContactDetails?: CherryPayApi.Contact;
  pointsBalance?: number | null;
}

const InnerForm = ({
  isSubmitting,
  isValid,
  values,
  dirty,
  businessId,
  member,
  memberFullName,
  onCancel,
  onSuccess,
  onFailure,
  setFieldValue,
  submitForm,
}: MemberFormProps & FormikProps<MemberFormValues>) => {
  const { data: venuesOptions, isLoading: venuesOptionsLoading } =
    useApiRequest(
      (apiClient) => apiClient.getHubMembershipVenues(businessId),
      []
    );

  useEffect(() => {
    if (venuesOptions && venuesOptions.length == 1) {
      setFieldValue("venueId", venuesOptions[0].Key);
    }
  }, [venuesOptions]);

  const {
    data: membershipTypesOptions,
    isLoading: membershipTypesOptionsLoading,
  } = useApiRequest(
    (apiClient) =>
      values.venueId || member?.id
        ? apiClient.getHubMembershipTypes(businessId, {
            venueId: values.venueId,
            memberId: member?.id,
          })
        : null,
    [values.venueId, member?.id]
  );

  const {
    data: membershipLevelsOptions,
    isLoading: membershipLevelsOptionsLoading,
  } = useApiRequest(
    (apiClient) =>
      values.venueId || member?.id
        ? apiClient.getHubMembershipLevels(businessId, {
            venueId: values.venueId,
            memberId: member?.id,
          })
        : null,
    [values.venueId, member?.id]
  );

  const { data: gendersOptions, isLoading: gendersOptionsLoading } =
    useApiRequest(
      (apiClient) =>
        values.venueId || member?.id
          ? apiClient.getHubMembershipGenders(businessId, {
              venueId: values.venueId,
              memberId: member?.id,
            })
          : null,
      [values.venueId, member?.id]
    );

  const { data: titlesOptions, isLoading: titlesOptionsLoading } =
    useApiRequest(
      (apiClient) =>
        values.venueId || member?.id
          ? apiClient.getHubMembershipTitles(businessId, {
              venueId: values.venueId,
              memberId: member?.id,
            })
          : null,
      [values.venueId, member?.id]
    );

  const { data: convertedHomeAddress, isLoading: isHomeLoading } =
    useApiRequest(
      (apiClient) =>
        values.geocodedHomeAddress
          ? apiClient.convertHubMembershipGeocodedToAddress(
              businessId,
              values.geocodedHomeAddress!
            )
          : null,
      [values.geocodedHomeAddress]
    );

  const { data: convertedWorkAddress, isLoading: isWorkLoading } =
    useApiRequest(
      (apiClient) =>
        values.geocodedWorkAddress
          ? apiClient.convertHubMembershipGeocodedToAddress(
              businessId,
              values.geocodedWorkAddress!
            )
          : null,
      [values.geocodedWorkAddress]
    );

  const { data: convertedPostalAddress, isLoading: isPostalLoading } =
    useApiRequest(
      (apiClient) =>
        values.geocodedPostalAddress
          ? apiClient.convertHubMembershipGeocodedToAddress(
              businessId,
              values.geocodedPostalAddress!
            )
          : null,
      [values.geocodedPostalAddress]
    );

  useEffect(() => {
    if (dirty) {
      setFieldValue("homeAddress", convertedHomeAddress);
    }
  }, [convertedHomeAddress]);

  useEffect(() => {
    if (dirty) {
      setFieldValue("workAddress", convertedWorkAddress);
    }
  }, [convertedWorkAddress]);

  useEffect(() => {
    if (dirty) {
      setFieldValue("postalAddress", convertedPostalAddress);
    }
  }, [convertedPostalAddress]);

  return (
    <Card w="100%">
      <Stack
        w="100%"
        alignItems="start"
        spacing={{ base: 4, xl: 24 }}
        bgColor="gray.600"
        py={5}
        px={8}
        borderTopStartRadius="3xl"
        borderTopEndRadius="3xl"
        direction={{ base: "column", xl: "row" }}
      >
        <VStack spacing="1" alignItems="flex-start">
          <Text fontSize="2xl" color="white" fontWeight="bold">
            {member != null ? memberFullName : "New Member"}
          </Text>
        </VStack>

        <HStack
          flex="1"
          justifyContent="end"
          position="relative"
          zIndex={100}
          alignSelf="center"
        >
          {(member !== null || !isEmptyStr(values.venueId)) && (
            <>
              <Box>
                <Button
                  colorScheme="cherryButton"
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={isSubmitting || !isValid}
                  onClick={submitForm}
                >
                  Save
                </Button>
              </Box>

              <Box>
                <Button disabled={isSubmitting} onClick={onCancel}>
                  Cancel
                </Button>
              </Box>
            </>
          )}
        </HStack>
      </Stack>
      {member === null && isEmptyStr(values.venueId) && (
        <VStack
          w="100%"
          p={8}
          color="cherryGrey.900"
          bgColor="white"
          borderBottomLeftRadius="3xl"
          borderBottomRightRadius="3xl"
          boxShadow="md"
        >
          <ChakraReactSelectField
            label="Please select the member's venue"
            name="venueId"
            selectedOptionColor="blue"
            isLoading={venuesOptionsLoading}
            options={
              venuesOptions?.map((provider) => {
                return {
                  label: provider.Value!,
                  value: provider.Key!,
                };
              }) || []
            }
            className="relative-listbox"
          />
        </VStack>
      )}
      {(member !== null || !isEmptyStr(values.venueId)) && (
        <>
          <Tabs variant="edit" w="100%" isLazy>
            <TabList>
              <HStack spacing={3} overflowX="auto">
                <Tab>Details</Tab>

                <Tab>Address</Tab>

                <Tab>Contact</Tab>
              </HStack>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Stack
                  w="100%"
                  align="start"
                  spacing={2}
                  direction={["column", "row"]}
                >
                  <VStack align="start" w="100%">
                    <Box
                      w="100%"
                      p={4}
                      border="1px solid"
                      borderColor="cherryButtonDark.50"
                      borderRadius="md"
                    >
                      <HStack width="100%" mb={2}>
                        <Text fontSize="sm" flex="1">
                          <b>Personal</b> :
                        </Text>
                      </HStack>
                      <Divider mb={2} />
                      <VStack
                        w="100%"
                        alignItems="flex-start"
                        spacing={6}
                        pb={10}
                      >
                        {titlesOptions && titlesOptions.length > 0 && (
                          <ChakraReactSelectField
                            label="Title"
                            name="title"
                            selectedOptionColor="blue"
                            isLoading={titlesOptionsLoading}
                            options={
                              titlesOptions?.map((provider) => {
                                return {
                                  label: provider.Value!,
                                  value: provider.Key!,
                                };
                              }) || []
                            }
                            className="relative-listbox"
                          />
                        )}
                        {(!titlesOptions || titlesOptions.length == 0) && (
                          <TextField
                            name="title"
                            label="Title"
                            placeholder="Title"
                          />
                        )}
                        <TextField
                          name="firstName"
                          label="First Name"
                          placeholder="First Name"
                        />
                        <TextField
                          name="preferredName"
                          label="Preferred Name"
                          placeholder="Preferred Name"
                        />

                        <TextField
                          name="middleName"
                          label="Middle Name"
                          placeholder="Middle Name"
                        />
                        <TextField
                          name="lastName"
                          label="Last Name"
                          placeholder="Last Name"
                        />

                        <DateField
                          name="birthDate"
                          label="Birth Date"
                          placeholder="Birth Date"
                        />

                        {gendersOptions && gendersOptions.length > 0 && (
                          <ChakraReactSelectField
                            label="Gender"
                            name="gender"
                            selectedOptionColor="blue"
                            isLoading={gendersOptionsLoading}
                            options={
                              gendersOptions?.map((provider) => {
                                return {
                                  label: provider.Value!,
                                  value: provider.Key!,
                                };
                              }) || []
                            }
                            className="relative-listbox"
                          />
                        )}
                        {(!gendersOptions || gendersOptions.length == 0) && (
                          <TextField
                            name="gender"
                            label="Gender"
                            placeholder="Gender"
                          />
                        )}
                      </VStack>
                    </Box>
                  </VStack>

                  <VStack align="start" w="100%">
                    <Box
                      w="100%"
                      p={4}
                      border="1px solid"
                      borderColor="cherryButtonDark.50"
                      borderRadius="md"
                    >
                      <HStack width="100%" mb={2}>
                        <Text fontSize="sm" flex="1">
                          <b>Membership</b> :
                        </Text>
                      </HStack>
                      <Divider mb={2} />
                      <VStack
                        w="100%"
                        alignItems="flex-start"
                        spacing={6}
                        pb={10}
                      >
                        <ChakraReactSelectField
                          label="Membership Type"
                          name="membershipType"
                          selectedOptionColor="blue"
                          isLoading={membershipTypesOptionsLoading}
                          options={
                            membershipTypesOptions?.map((provider) => {
                              return {
                                label: provider.Value!,
                                value: provider.Key!,
                              };
                            }) || []
                          }
                          className="relative-listbox"
                          onChange={(e) => {
                            const label = (e as SingleValue<SelectOption>)
                              ?.label;
                            setFieldValue("membershipTypeDescription", label);
                          }}
                        />
                        {membershipLevelsOptions &&
                          membershipLevelsOptions.length > 0 && (
                            <ChakraReactSelectField
                              label="Member Level"
                              name="memberLevel"
                              selectedOptionColor="blue"
                              isLoading={membershipLevelsOptionsLoading}
                              options={
                                membershipLevelsOptions?.map((provider) => {
                                  return {
                                    label: provider.Value!,
                                    value: provider.Key!,
                                  };
                                }) || []
                              }
                              className="relative-listbox"
                              onChange={(e) => {
                                const label = (e as SingleValue<SelectOption>)
                                  ?.label;
                                setFieldValue("memberLevelDescription", label);
                              }}
                            />
                          )}
                        {(!membershipLevelsOptions ||
                          membershipLevelsOptions.length == 0) && (
                          <>
                            <TextField
                              name="memberLevel"
                              label="Member Level"
                              placeholder="Member Level"
                            />
                            <TextField
                              name="membershipLevelDescription"
                              label="Member Level Description"
                              placeholder="Member Level Description"
                            />
                          </>
                        )}
                      </VStack>
                    </Box>
                    <Box
                      w="100%"
                      p={4}
                      border="1px solid"
                      borderColor="cherryButtonDark.50"
                      borderRadius="md"
                    >
                      <HStack width="100%" mb={2}>
                        <Text fontSize="sm" flex="1">
                          <b>Other</b> :
                        </Text>
                      </HStack>
                      <Divider mb={2} />
                      <VStack
                        w="100%"
                        alignItems="flex-start"
                        spacing={6}
                        pb={10}
                      >
                        <TextField
                          name="occupation"
                          label="Occupation"
                          placeholder="Occupation"
                        />

                        <TextField
                          name="nationality"
                          label="Nationality"
                          placeholder="Nationality"
                        />
                      </VStack>
                    </Box>
                  </VStack>
                </Stack>
              </TabPanel>

              <TabPanel>
                <VStack align="start" w="100%">
                  <ChakraReactSelectField
                    horizontal
                    label="Default Address"
                    name="defaultAddress"
                    selectedOptionColor="blue"
                    options={AddressTypeList()}
                    fcProps={{ maxW: "50%" }}
                    valueProps={{ flex: 2 }}
                  />
                  <Stack
                    w="100%"
                    align="start"
                    direction={["column", "row"]}
                    spacing={2}
                  >
                    <MemberAddressForm
                      heading="Home Address"
                      addressFieldName="homeAddress"
                      geocodedAddressFieldName="geocodedHomeAddress"
                      address={values.homeAddress}
                      tempGeocodedAddress={values.tempGeocodedAddress}
                      setFieldValue={setFieldValue}
                    />
                    <MemberAddressForm
                      heading="Work Address"
                      addressFieldName="workAddress"
                      geocodedAddressFieldName="geocodedWorkAddress"
                      tempGeocodedAddress={values.tempGeocodedAddress}
                      setFieldValue={setFieldValue}
                    />
                    <MemberAddressForm
                      heading="Postal Address"
                      addressFieldName="postalAddress"
                      geocodedAddressFieldName="geocodedPostalAddress"
                      tempGeocodedAddress={values.tempGeocodedAddress}
                      setFieldValue={setFieldValue}
                    />
                  </Stack>
                </VStack>
              </TabPanel>

              <TabPanel>
                <VStack align="start" w="100%">
                  <ChakraReactSelectField
                    horizontal
                    label="Default Contact Details"
                    name="defaultContactDetails"
                    selectedOptionColor="blue"
                    options={ContactTypeList()}
                    fcProps={{ maxW: "50%" }}
                    valueProps={{ flex: 2 }}
                  />
                  <Stack
                    w="100%"
                    align="start"
                    direction={["column", "row"]}
                    spacing={2}
                  >
                    <MemberContactForm
                      contactFieldName="homeContactDetails"
                      heading="Home Contact Details"
                    />
                    <MemberContactForm
                      contactFieldName="workContactDetails"
                      heading="Work Contact Details"
                    />
                    <MemberContactForm
                      contactFieldName="postalContactDetails"
                      heading="Postal Contact Details"
                    />
                  </Stack>
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <HStack width="100%" justifyContent="center" spacing="3" pt={8}>
            <Button
              colorScheme="cherryButton"
              type="submit"
              isLoading={isSubmitting}
              disabled={isSubmitting || !isValid}
              onClick={submitForm}
            >
              Save
            </Button>
            <Button disabled={isSubmitting} onClick={onCancel}>
              Cancel
            </Button>
          </HStack>
        </>
      )}
    </Card>
  );
};

export const MemberForm = withFormik<MemberFormProps, MemberFormValues>({
  mapPropsToValues: ({ member }) => {
    return {
      memberKey: member?.MemberKey,
      membershipNumber: member?.MembershipNumber,
      badgeNumber: member?.BadgeNumber ?? "",
      title: member?.Title ?? "",
      cardNumber: member?.CardNumber ?? "",
      firstName: member?.FirstName ?? "",
      preferredName: member?.PreferredName ?? "",
      middleName: member?.MiddleName ?? "",
      lastName: member?.LastName ?? "",
      birthDate: member?.BirthDate ?? "",
      nationality: member?.Nationality ?? "",
      dateJoined: member?.DateJoined ?? "",
      dateRenewed: member?.DateRenewed ?? "",
      dateFinancial: member?.DateFinancial ?? "",
      financial: member?.Financial ?? false,
      status: member?.Status ?? "",
      gender: member?.Gender ?? "",
      occupation: member?.Occupation ?? "",
      memberLevel: member?.MemberLevel ?? "",
      memberLevelDescription: member?.MemberLevelDescription ?? "",
      membershipType: member?.MembershipType ?? "",
      membershipTypeDescription: member?.MembershipTypeDescription ?? "",
      defaultAddress: member?.DefaultAddress as AddressType,
      homeAddress: member?.HomeAddress ?? undefined,
      workAddress: member?.WorkAddress ?? undefined,
      postalAddress: member?.PostalAddress ?? undefined,
      defaultContactDetails: member?.DefaultContactDetails as ContactType,
      homeContactDetails: member?.HomeContactDetails,
      workContactDetails: member?.WorkContactDetails,
      postalContactDetails: member?.PostalContactDetails,
      pointsBalance: member?.PointsBalance ?? 0,
    };
  },

  handleSubmit: async (values, { props, setFieldError }) => {
    let isInvalid = false;
    //only validate venue when new member
    if (isEmptyStr(values.venueId) && isEmptyStr(values.memberKey)) {
      setFieldError("venueId", "Venue is required");
      isInvalid = true;
    }

    if (isEmptyStr(values.membershipType)) {
      setFieldError("membershipType", "Membership Type is required");
      isInvalid = true;
    }

    if (isEmptyStr(values.firstName)) {
      setFieldError("firstName", "First Name is required");
      isInvalid = true;
    }

    if (isEmptyStr(values.lastName)) {
      setFieldError("lastName", "Last Name is required");
      isInvalid = true;
    }

    if (
      values.homeContactDetails &&
      !isEmptyStr(values.homeContactDetails.EmailAddress) &&
      !isValidEmail(values.homeContactDetails.EmailAddress)
    ) {
      setFieldError(
        "homeContactDetails.EmailAddress",
        "Invalid home email address"
      );
    }

    if (
      values.workContactDetails &&
      !isEmptyStr(values.workContactDetails.EmailAddress) &&
      !isValidEmail(values.workContactDetails.EmailAddress)
    ) {
      setFieldError(
        "workContactDetails.EmailAddress",
        "Invalid work email address"
      );
    }

    if (
      values.postalContactDetails &&
      !isEmptyStr(values.postalContactDetails.EmailAddress) &&
      !isValidEmail(values.postalContactDetails.EmailAddress)
    ) {
      setFieldError(
        "postalContactDetails.EmailAddress",
        "Invalid postal email address"
      );
    }

    if (isInvalid) {
      return;
    }

    const memberUpdate: Partial<CherryPayApi.MemberUpdate> = {
      MemberKey: values.memberKey ?? uuid().replace(/-/g, ""),
      MembershipNumber: values.membershipNumber,
      BadgeNumber: values.badgeNumber,
      CardNumber: values.cardNumber,
      Title: values.title,
      FirstName: values.firstName,
      PreferredName: values.preferredName,
      MiddleName: values.middleName,
      LastName: values.lastName,
      BirthDate: values.birthDate,
      Nationality: values.nationality,
      DateJoined: values.dateJoined,
      DateRenewed: values.dateRenewed,
      DateFinancial: values.dateFinancial,
      Financial: values.financial,
      Status: values.status,
      Gender: values.gender,
      Occupation: values.occupation,
      MemberLevel: values.memberLevel,
      MemberLevelDescription: values.memberLevelDescription,
      MembershipType: values.membershipType,
      MembershipTypeDescription: values.membershipTypeDescription,
      DefaultAddress: values.defaultAddress,
      HomeAddress: values.homeAddress ?? undefined,
      WorkAddress: values.workAddress ?? undefined,
      PostalAddress: values.postalAddress ?? undefined,
      DefaultContactDetails: values.defaultContactDetails,
      HomeContactDetails: values.homeContactDetails,
      WorkContactDetails: values.workContactDetails,
      PostalContactDetails: values.postalContactDetails,
      PointsBalance: values.pointsBalance,
    };

    let result: ApiResult<CherryPayApi.Member> | null = null;

    if (props.member == null) {
      result = await props.apiClient.createHubMembershipMember(
        memberUpdate,
        props.businessId,
        {
          venueId: values.venueId,
        }
      );
    } else {
      result = await props.apiClient.updateHubMembershipMember(
        memberUpdate,
        props.businessId,
        props.member.id
      );
    }

    if (result.ok) {
      if (props.onSuccess) {
        props.onSuccess(result.data);
      }
    } else {
      if (props.onFailure) {
        props.onFailure(result.message);
      }
    }
  },

  validateOnMount: false,
  validateOnBlur: true,

  validate: (values) => {
    let errors: FormikErrors<MemberFormValues> = {};

    return errors;
  },
})(InnerForm);
