import React from "react";
import { Tooltip } from "@chakra-ui/react";
import { Box } from "@chakra-ui/layout";
import { XmarkIcon } from "../../styles/icons";
import { useApiClient } from "../../hooks/useApiClient";
import { CherryPayApi } from "../../api/models";
import { useToast } from "../../hooks/useToast";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";

interface CardOrderActionsProps {
  businessId: string;
  cardOrderId: string;
  orderStatus: CherryPayApi.CherryPayCardOrderStatus;
  refreshTable: () => void;
}

export const CardOrderCancelAction = ({
  businessId,
  cardOrderId,
  orderStatus,
  refreshTable,
}: CardOrderActionsProps) => {
  const apiClient = useApiClient();
  const { showErrorToast, showSuccessToast } = useToast();
  const { confirmationElement, showConfirmationDialog } =
    useConfirmationDialog();

  const onCancelOrder = () => {
    showConfirmationDialog(
      "Cancel Order?",
      `Are you sure you wish to cancel the order ${cardOrderId.substring(
        0,
        8
      )}?`,
      "Yes, Cancel Order",
      "No, Do not cancel"
    ).then(({ ok }) => {
      if (ok) {
        apiClient
          .cancelCardOrder(businessId, cardOrderId)
          .then((result) => {
            if (result.ok) {
              showSuccessToast("Order cancelled successfully");
              refreshTable();
            } else {
              showErrorToast(
                "Failed to cancel order. Error: " + result.message
              );
            }
          })
          .catch((error) => {
            showErrorToast("Failed to cancel order. Error: " + error.message);
          });
      }
    });
  };

  const isIconEnabled = orderStatus === "Incomplete";
  const tooltipText = isIconEnabled
    ? "Cancel Order"
    : "Only Incomplete orders can be cancelled";

  return (
    <Box mr={2}>
      <Tooltip label={tooltipText} fontSize="md">
        <span>
          <XmarkIcon
            onClick={isIconEnabled ? onCancelOrder : undefined}
            style={{
              cursor: isIconEnabled ? "pointer" : "not-allowed",
              opacity: isIconEnabled ? 1 : 0.5,
            }}
          />
        </span>
      </Tooltip>
      {confirmationElement}
    </Box>
  );
};
