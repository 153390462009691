import { WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { CherryPayApi } from "../../api/models";
import { BusinessBreadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { Card } from "../../components/Card/Card";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { SearchInput } from "../../components/SearchInput/SearchInput";
import { PreviewButton } from "../../components/Utilities/PreviewButton";
import { useBusinessContext } from "../../context/ModelContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import { usePaginatedApiRequest } from "../../hooks/usePaginatedApiRequest";
import { useSearchInputState } from "../../hooks/useSearchInputState";
import DateUtil from "../../util/DateUtil";
import { isEmptyStr } from "../../util/StringUtil";

export const MembershipRequest = () => {
  const business = useBusinessContext();
  const { query, debouncedQuery, onChange, isDebouncing, setQuery } =
    useSearchInputState("");
  const [showDeleted, setShowDeleted] = useState(false);
  const [registration, setRegistration] = useState<string>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [rowNumber, setRowNumber] = useState(10);
  const [isFinalised, setIsFinalised] = useState<boolean>();
  const [act, setAct] = useState(0);

  const run = () => {
    setAct((prev) => ++prev);
  };

  const {
    data: registrations,
    error: registrationsError,
    isLoading: registrationsLoading,
  } = useApiRequest(
    (apiClient) =>
      apiClient.getRegistrationFormConfigurationSummaryItems(
        business.BusinessId
      ),
    [business]
  );

  const {
    items: membershipRequests,
    isLoading,
    isLoadingPage,
    error,
    fetchNextPage,
  } = usePaginatedApiRequest(
    (apiClient) => {
      const start = DateUtil.setStartEndDate(startDate, "start");
      const end = DateUtil.setStartEndDate(endDate, "end");
      return apiClient.getMembershipRequests(business.BusinessId, {
        includeDeleted: showDeleted,
        q: debouncedQuery,
        startDate: start,
        endDate: end,
        limit: rowNumber,
        isFinalised,
        registrationFormConfigurationId: registration,
      });
    },
    (apiClient, continuationToken) => {
      const start = DateUtil.setStartEndDate(startDate, "start");
      const end = DateUtil.setStartEndDate(endDate, "end");
      return apiClient.getMembershipRequests(business.BusinessId, {
        continuationToken: continuationToken,
        includeDeleted: showDeleted,
        q: debouncedQuery,
        startDate: start,
        endDate: end,
        limit: rowNumber,
        isFinalised,
        registrationFormConfigurationId: registration,
      });
    },
    [act, debouncedQuery]
  );

  const programTypeOptions = useMemo(
    () =>
      registrations?.map((p) => {
        return (
          <option value={p.Meta?.Id!} key={p.Meta?.Id}>
            {p.DisplayName}
          </option>
        );
      }),
    [registrations]
  );

  const getSubmissionFinalisationColumn = (
    item: CherryPayApi.MembershipRequestSummaryItem
  ) => {
    const finalisation = item.SubmissionFinalisation;
    if (!finalisation) return <>-</>;
    if (!finalisation.IsFinalised && !isEmptyStr(item.MembershipRequestUrl))
      return (
        <PreviewButton url={item.MembershipRequestUrl!}>Finalise</PreviewButton>
      );
    if (!finalisation.IsFinalised) return <>Not Finalised</>;
    else if (
      finalisation.SubmissionAccepted &&
      !isEmptyStr(item.MembershipRequestUrl)
    )
      return (
        <Flex alignItems={"flex-start"} flexDirection={"column"}>
          <PreviewButton url={item.MembershipRequestUrl!} mb={1}>
            Accepted{" "}
          </PreviewButton>
          <Box as={"span"} ml={2}>
            {DateUtil.getLocalDateFormatFromString(finalisation.FinalisedDate)}
          </Box>
        </Flex>
      );
    else if (finalisation.SubmissionAccepted)
      return (
        <>
          Accepted{" "}
          {DateUtil.getLocalDateFormatFromString(finalisation.FinalisedDate)}
        </>
      );
    else if (
      !finalisation.SubmissionAccepted &&
      !isEmptyStr(item.MembershipRequestUrl)
    )
      return (
        <Flex alignItems={"flex-start"} flexDirection={"column"}>
          <PreviewButton url={item.MembershipRequestUrl!} mb={1}>
            Rejected{" "}
          </PreviewButton>
          <Box as={"span"} ml={2}>
            {DateUtil.getLocalDateFormatFromString(finalisation.FinalisedDate)}
          </Box>
        </Flex>
      );
    else if (!finalisation.SubmissionAccepted)
      return (
        <>
          Rejected{" "}
          {DateUtil.getLocalDateFormatFromString(finalisation.FinalisedDate)}
        </>
      );
    else return <>-</>;
  };

  const getSubmissionFinalisationTitle = (
    finalisation?: CherryPayApi.SubmissionFinalisation
  ) => {
    if (!finalisation || !finalisation.IsFinalised)
      return "This request has not been finalised";
    return `Finalised by ${finalisation.FinalisedByUser}\r\n${
      finalisation.AcceptanceDetails
    }\r\nNote: ${finalisation.RejectionNoteByUser ?? "None"}`;
  };

  const requestRows = useMemo(
    () =>
      (membershipRequests.length > 0 &&
        membershipRequests?.map((item) => {
          const formattedDate = DateUtil.getLocalDateFormatFromString(
            item.RequestSubmittedDateTime
          );
          return (
            <Tr key={item.SessionId}>
              {registrations && registrations.length > 1 && (
                <Td whiteSpace="nowrap">
                  {item.RegistrationFormConfigurationName}
                </Td>
              )}
              <Td whiteSpace="nowrap">{item.SessionId}</Td>
              <Td whiteSpace="nowrap">
                {item.MembershipNumber && (
                  <PreviewButton
                    hover={true}
                    color="gray.600"
                    linkProps={{ target: "_self" }}
                    url={`/businesses/${business.BusinessId}/members/${item.MemberId}`}
                  >
                    {item.MembershipNumber}
                  </PreviewButton>
                )}
              </Td>
              <Td>
                {/* <Link
                as={ReactRouterLink}
                to={`/businesses/${business.BusinessId}/membership-requests/${item.SessionId}`}
              > */}
                {item.DisplayName}
                {/* </Link> */}
              </Td>
              <Td>{formattedDate}</Td>
              <Td title={item.Message ?? "-"}>
                {item.NextStep}&nbsp;
                {item.IsError && <WarningIcon color="cherryButton.500" />}
              </Td>
              <Td
                title={getSubmissionFinalisationTitle(
                  item.SubmissionFinalisation
                )}
              >
                {getSubmissionFinalisationColumn(item)}
              </Td>
            </Tr>
          );
        })) || (
        <Tr>
          <Td colSpan={5} textAlign="center">
            <i>No Membership Request</i>
          </Td>
        </Tr>
      ),
    [membershipRequests]
  );
  return (
    <>
      <PageHeading>
        <BusinessBreadcrumbs>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={ReactRouterLink}
              to={`/businesses/${business.BusinessId}/membership-requests`}
            >
              Membership Requests
            </BreadcrumbLink>
          </BreadcrumbItem>
        </BusinessBreadcrumbs>
        <PageHeading.Title>Membership Requests</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <VStack alignItems="start" w="100%">
          <Card w="100%">
            <Flex gridGap={6} className="form-filter-wrapper">
              <Box pt={8}>
                <SearchInput
                  value={query}
                  onChange={onChange}
                  isLoading={isLoading || isDebouncing}
                  placeholder="Search requests..."
                  setQuery={setQuery}
                />
              </Box>
              {registrations && registrations.length > 1 && (
                <Box>
                  <FormControl>
                    <FormLabel>Application Form</FormLabel>

                    <Select
                      placeholder="All application forms"
                      onChange={(e) => setRegistration(e.target.value)}
                      defaultValue={registration}
                    >
                      {programTypeOptions}
                    </Select>
                  </FormControl>
                </Box>
              )}
              {registrationsLoading && (
                <Center>
                  <Spinner />
                </Center>
              )}
              {registrationsError && (
                <ErrorMessage>
                  An error was encountered while loading the program types.
                </ErrorMessage>
              )}
              <Box>
                <FormControl>
                  <FormLabel>Request Submitted from</FormLabel>
                  <Input
                    type="date"
                    placeholder="Request Submitted from"
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel>Request Submitted to</FormLabel>
                  <Input
                    type="date"
                    placeholder="Request Submitted to"
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel>Is Finalised</FormLabel>
                  <Select
                    onChange={(e) =>
                      setIsFinalised(
                        e.target.value === "All"
                          ? undefined
                          : e.target.value == "true"
                          ? true
                          : false
                      )
                    }
                    value={
                      isFinalised === undefined
                        ? undefined
                        : isFinalised
                        ? "true"
                        : "false"
                    }
                  >
                    <option value={undefined}>All</option>
                    <option value="true">Finalised</option>
                    <option value="false">Not Finalised</option>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel>Rows</FormLabel>
                  <Select
                    onChange={(e) => setRowNumber(parseInt(e.target.value))}
                    value={rowNumber}
                    width="20"
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                  </Select>
                </FormControl>
              </Box>

              {/* <Box p="4" pt={12}>
              <Checkbox
                size="lg"
                onChange={() => setShowDeleted((prev) => !prev)}
                isChecked={showDeleted}
              >
                Show Deleted
              </Checkbox>
            </Box> */}
              <Box alignSelf="flex-end" className="form-filter-button-wrapper">
                <Button colorScheme="cherryButton" onClick={run}>
                  Search
                </Button>
              </Box>
            </Flex>
          </Card>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {!membershipRequests && isLoading && (
            <Center>
              <Spinner margin="4" />
            </Center>
          )}
          {membershipRequests && !isLoading && (
            <Card w="100%">
              <Table w="100%" size="sm">
                <Thead>
                  <Tr>
                    {registrations && registrations.length > 1 && (
                      <Th>Application Form</Th>
                    )}
                    <Th>Session ID</Th>
                    <Th whiteSpace="nowrap">Membership Number</Th>
                    <Th>Patron Name</Th>
                    <Th>Date</Th>
                    <Th>Next Step</Th>
                    <Th>Submission Finalisation</Th>
                  </Tr>
                </Thead>
                <Tbody>{requestRows}</Tbody>
              </Table>
            </Card>
          )}
          {!isLoading && fetchNextPage && (
            <Card w="100%">
              {!isLoadingPage && (
                <Button colorScheme="cherryButton" onClick={fetchNextPage}>
                  Load more...
                </Button>
              )}
              {isLoadingPage && <Spinner />}
            </Card>
          )}
        </VStack>
      </PageContent>
    </>
  );
};
